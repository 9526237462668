jQuery(document).ready(function($) {
	/* Mobile Menu */
	if ($('.mobile-navigation-menu').length) {
		mobileNavigationMenuSelector = ".mobile-navigation-menu"
		menu = new Mmenu(mobileNavigationMenuSelector, {
			extensions: [
				"position-right"
			],
			navbar: {
				add: true,
				title: ""
			},
			screenReader: {
				aria: false,
				text: true,
			}
		});

		api = menu.API;
		$(".mobile-menu-button").click(function() {
			if ($(mobileNavigationMenuSelector).hasClass("mm-menu_opened")) {
				api.close();
			}
			else {
				api.open();
			}
		});
	}

	/* FitVids */
	if ($("body").innerWidth() <= 767) {
		$(".inside-row").fitVids({
			ignore: "nofit"
		});
		$(".cboxes").fitVids({
			ignore: "nofit"
		});
	}

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .dropdown-toggle").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".dropdown").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".dropdown").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();


	/* Sticky Header */
	addStickyHeader();
	$(window).scroll(function() {
		addStickyHeader();
	});


	/* Alert Box */
	$(".closeAlertBox").click(function() {
		$(".alert-header-section-wrapper").slideUp(280);
		setCookie("alertBox", "isShown");
	});

	if (document.cookie.indexOf("alertBox=") == -1) {
		$(".alert-header-section-wrapper").show();
	}

	/* Alert Popup */
	$(".alert-popup-close,.alert-popup-close-btn").click(function() {
		$(".alert-pop-up-wrapper").fadeOut(150);
		setCookie("alertPopup", "isShown");
	});

	if (document.cookie.indexOf("alertPopup=") == -1) {
		$(".alert-pop-up-wrapper").show();
	}

	/* Remove underline from link images */
	$("a > img").parent().css({'border-bottom': '0'});

	/* eslint-disable */
	/* Webflow */
	/* Webflow: Interactions: Init*/
	Webflow.require('ix').init([{
			"slug": "gdpr-open",
			"name": "GDPR Open",
			"value": {
				"style": {},
				"triggers": [{
					"type": "click",
					"selector": ".gdrp-section",
					"stepsA": [{
						"display": "flex",
						"opacity": 1,
						"height": "auto",
						"transition": "height 200 ease 0, opacity 200 ease 0"
					}],
					"stepsB": []
				}]
			}
		},
		{
			"slug": "gdpr-close",
			"name": "GDPR Close",
			"value": {
				"style": {},
				"triggers": [{
					"type": "click",
					"selector": ".gdrp-section",
					"stepsA": [{
						"display": "none",
						"opacity": 0,
						"height": "0px",
						"transition": "height 200 ease 0, opacity 200 ease 0"
					}],
					"stepsB": []
				}]
			}
		}
	]);
	/* Webflow: Interactions 2.0: Init*/
	Webflow.require('ix2').init({
		"events": {
			"e": {
				"id": "e",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_CLICK",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-34"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "ed25609c-d8fa-86e2-7ce7-2122c6f0e9c0",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "ed25609c-d8fa-86e2-7ce7-2122c6f0e9c0",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1610572739711
			},
			"e-17": {
				"id": "e-17",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-7",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-118"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225d0",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225d0",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625757658602
			},
			"e-18": {
				"id": "e-18",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-8",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-121"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225d0",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225d0",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625757658602
			},
			"e-23": {
				"id": "e-23",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-7",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-117"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225e9",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225e9",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625834656156
			},
			"e-24": {
				"id": "e-24",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-8",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-114"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225e9",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225e9",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625834656156
			},
			"e-25": {
				"id": "e-25",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-7",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-115"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22600",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22600",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625835464509
			},
			"e-26": {
				"id": "e-26",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-8",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-120"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22600",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22600",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625835464509
			},
			"e-27": {
				"id": "e-27",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-7",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-119"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22617",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22617",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625839499705
			},
			"e-28": {
				"id": "e-28",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-8",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-113"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22617",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22617",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625839499705
			},
			"e-31": {
				"id": "e-31",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-7",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-122"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22628",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22628",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625839624309
			},
			"e-32": {
				"id": "e-32",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-8",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-116"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22628",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22628",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625839624309
			},
			"e-33": {
				"id": "e-33",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-34"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|8a4e7572-5d1e-341d-5225-f53c2a807555",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|8a4e7572-5d1e-341d-5225-f53c2a807555",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1599771774359
			},
			"e-34": {
				"id": "e-34",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-10",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-33"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|8a4e7572-5d1e-341d-5225-f53c2a807555",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|8a4e7572-5d1e-341d-5225-f53c2a807555",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1599771774360
			},
			"e-35": {
				"id": "e-35",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_CLICK",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-6",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-36"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22663",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22663",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1626295232273
			},
			"e-37": {
				"id": "e-37",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_CLICK",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-14",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-38"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "ed25609c-d8fa-86e2-7ce7-2122c6f0e9ca",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "ed25609c-d8fa-86e2-7ce7-2122c6f0e9ca",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1626295413589
			},
			"e-41": {
				"id": "e-41",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-16",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-42"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"selector": ".alert-cb",
					"originalId": "611d5acd7fdfc912d94e1941|4b0dedaf-d1af-1cad-45f7-9ede2d0ea7db",
					"appliesTo": "CLASS"
				},
				"targets": [{
					"selector": ".alert-cb",
					"originalId": "611d5acd7fdfc912d94e1941|4b0dedaf-d1af-1cad-45f7-9ede2d0ea7db",
					"appliesTo": "CLASS"
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1626960953415
			},
			"e-42": {
				"id": "e-42",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-17",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-41"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"selector": ".alert-cb",
					"originalId": "611d5acd7fdfc912d94e1941|4b0dedaf-d1af-1cad-45f7-9ede2d0ea7db",
					"appliesTo": "CLASS"
				},
				"targets": [{
					"selector": ".alert-cb",
					"originalId": "611d5acd7fdfc912d94e1941|4b0dedaf-d1af-1cad-45f7-9ede2d0ea7db",
					"appliesTo": "CLASS"
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1626960953417
			},
			"e-43": {
				"id": "e-43",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-44"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|4b0dedaf-d1af-1cad-45f7-9ede2d0ea7db",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|4b0dedaf-d1af-1cad-45f7-9ede2d0ea7db",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": 0,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626961205093
			},
			"e-45": {
				"id": "e-45",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-46"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|750e7b17-6872-3a95-4ce5-fd6dae93124a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|750e7b17-6872-3a95-4ce5-fd6dae93124a",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": 250,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626961227611
			},
			"e-45a": {
				"id": "e-45a",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-46"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|750e7b17-6872-3a95-4ce5-fd6dae93124b",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|750e7b17-6872-3a95-4ce5-fd6dae93124b",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": 250,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626961227611
			},
			"e-47": {
				"id": "e-47",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-18",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-48"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|2bb8c352-25a0-986b-7f9c-3ad256c9db10",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|2bb8c352-25a0-986b-7f9c-3ad256c9db10",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1626961316351
			},
			"e-49": {
				"id": "e-49",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-50"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|618b0d6b-1c28-c876-f018-c14baaef837c",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|618b0d6b-1c28-c876-f018-c14baaef837c",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": 0,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626961480153
			},
			"e-51": {
				"id": "e-51",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-52"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|838a9ae1-0925-3e8a-ca41-c767fdbadd32",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|838a9ae1-0925-3e8a-ca41-c767fdbadd32",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": 250,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626961495956
			},
			"e-57": {
				"id": "e-57",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-58"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|3e801a5f-45d4-76d6-9fd1-8719569f459f",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|3e801a5f-45d4-76d6-9fd1-8719569f459f",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 0,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626962420839
			},
			"e-59": {
				"id": "e-59",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-60"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d72",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d72",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 250,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626962445066
			},
			"e-63": {
				"id": "e-63",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-64"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d81",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d81",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 600,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626962478580
			},
			"e-65": {
				"id": "e-65",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-66"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|c635e001-cb7b-0f45-4668-89d32f387c1f",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|c635e001-cb7b-0f45-4668-89d32f387c1f",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 250,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626962502344
			},
			"e-68": {
				"id": "e-68",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-67"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|73ad9ea6-fbca-80b3-bbff-bc02d94ccf58",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|73ad9ea6-fbca-80b3-bbff-bc02d94ccf58",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 0,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626962531781
			},
			"e-69": {
				"id": "e-69",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-70"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|ce505746-0e7b-574a-aa46-4963b52de3f4",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|ce505746-0e7b-574a-aa46-4963b52de3f4",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 250,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626962543714
			},
			"e-71": {
				"id": "e-71",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-72"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|ce505746-0e7b-574a-aa46-4963b52de3f7",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|ce505746-0e7b-574a-aa46-4963b52de3f7",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 400,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626962560829
			},
			"e-73": {
				"id": "e-73",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-74"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|17f3d786-f663-0192-278d-28017cc7a957",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|17f3d786-f663-0192-278d-28017cc7a957",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": 0,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626962646114
			},
			"e-75": {
				"id": "e-75",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-76"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|ab36d125-df83-6cf7-ea04-388ee6f7a2cc",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|ab36d125-df83-6cf7-ea04-388ee6f7a2cc",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 250,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626962664944
			},
			"e-77": {
				"id": "e-77",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-78"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|dba58a24-2d5c-cea1-9e9b-bba0965f48f1",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|dba58a24-2d5c-cea1-9e9b-bba0965f48f1",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": 400,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626962677153
			},
			"e-79": {
				"id": "e-79",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-80"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|5a5b4c24-4dfa-2369-fd4d-378c73feaf3e",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|5a5b4c24-4dfa-2369-fd4d-378c73feaf3e",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": 600,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626962706118
			},
			"e-85": {
				"id": "e-85",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-21",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-86"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d76",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d76",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1626962966049
			},
			"e-86": {
				"id": "e-86",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-22",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-85"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d76",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d76",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1626962966053
			},
			"e-87": {
				"id": "e-87",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-21",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-88"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d81",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d81",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1626963002087
			},
			"e-88": {
				"id": "e-88",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-22",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-87"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d81",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d81",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1626963002089
			},
			"e-89": {
				"id": "e-89",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"instant": false,
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-90"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d76",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|4ab91b47-72a9-a112-9e5e-e05512633d76",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 400,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1626963036645
			},
			"e-93": {
				"id": "e-93",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-94"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc90bf44e1944|f9f3fe56-6cbf-c8e5-f415-5c164344af98",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc90bf44e1944|f9f3fe56-6cbf-c8e5-f415-5c164344af98",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1628111279733
			},
			"e-94": {
				"id": "e-94",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-10",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-93"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc90bf44e1944|f9f3fe56-6cbf-c8e5-f415-5c164344af98",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc90bf44e1944|f9f3fe56-6cbf-c8e5-f415-5c164344af98",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1628111279733
			},
			"e-95": {
				"id": "e-95",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-96"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc94f714e194b|ba197fc2-05a6-e9e7-6a06-b96ace98097e",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc94f714e194b|ba197fc2-05a6-e9e7-6a06-b96ace98097e",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1628111288338
			},
			"e-96": {
				"id": "e-96",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-10",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-95"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc94f714e194b|ba197fc2-05a6-e9e7-6a06-b96ace98097e",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc94f714e194b|ba197fc2-05a6-e9e7-6a06-b96ace98097e",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1628111288338
			},
			"e-97": {
				"id": "e-97",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-98"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc9eaf54e1942|c00530e7-f1ab-45b1-4db7-4f5315b66175",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc9eaf54e1942|c00530e7-f1ab-45b1-4db7-4f5315b66175",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1628111294432
			},
			"e-98": {
				"id": "e-98",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-10",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-97"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc9eaf54e1942|c00530e7-f1ab-45b1-4db7-4f5315b66175",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc9eaf54e1942|c00530e7-f1ab-45b1-4db7-4f5315b66175",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1628111294432
			},
			"e-99": {
				"id": "e-99",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-100"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc9af564e194c|60781661-7138-ad20-2f56-f0c7ebe7708f",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc9af564e194c|60781661-7138-ad20-2f56-f0c7ebe7708f",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1628111305814
			},
			"e-100": {
				"id": "e-100",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-10",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-99"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc9af564e194c|60781661-7138-ad20-2f56-f0c7ebe7708f",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc9af564e194c|60781661-7138-ad20-2f56-f0c7ebe7708f",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1628111305814
			},
			"e-105": {
				"id": "e-105",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-106"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc97bc24e1947|e65d81f4-dc7c-d65d-faff-843fe66dec97",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc97bc24e1947|e65d81f4-dc7c-d65d-faff-843fe66dec97",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1628800078804
			},
			"e-106": {
				"id": "e-106",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-10",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-105"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc97bc24e1947|e65d81f4-dc7c-d65d-faff-843fe66dec97",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc97bc24e1947|e65d81f4-dc7c-d65d-faff-843fe66dec97",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1628800078804
			},
			"e-111": {
				"id": "e-111",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-112"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc918f94e1949|e65d81f4-dc7c-d65d-faff-843fe66dec97",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc918f94e1949|e65d81f4-dc7c-d65d-faff-843fe66dec97",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1628884519681
			},
			"e-112": {
				"id": "e-112",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-10",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-111"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611d5acd7fdfc918f94e1949|e65d81f4-dc7c-d65d-faff-843fe66dec97",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc918f94e1949|e65d81f4-dc7c-d65d-faff-843fe66dec97",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1628884519681
			},
			"e-113": {
				"id": "e-113",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-23",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-119"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22617",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22617",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625839499705
			},
			"e-114": {
				"id": "e-114",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-23",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-117"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225e9",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225e9",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625834656156
			},
			"e-115": {
				"id": "e-115",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-24",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-120"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22600",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22600",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625835464509
			},
			"e-116": {
				"id": "e-116",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-23",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-122"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22628",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22628",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625839624309
			},
			"e-117": {
				"id": "e-117",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-24",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-114"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225e9",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225e9",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625834656156
			},
			"e-118": {
				"id": "e-118",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-24",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-121"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225d0",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225d0",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625757658602
			},
			"e-119": {
				"id": "e-119",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-24",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-113"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22617",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22617",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625839499705
			},
			"e-120": {
				"id": "e-120",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-23",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-115"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22600",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22600",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625835464509
			},
			"e-121": {
				"id": "e-121",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-23",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-118"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225d0",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d225d0",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625757658602
			},
			"e-122": {
				"id": "e-122",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-24",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-116"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22628",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "60fc3866-dc51-4f28-b8f2-2c6e20d22628",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1625839624309
			},
			"e-123": {
				"id": "e-123",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-11",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-124"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "6127cdc6ce8538204e9ca32b|e65d81f4-dc7c-d65d-faff-843fe66dec97",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "6127cdc6ce8538204e9ca32b|e65d81f4-dc7c-d65d-faff-843fe66dec97",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1629998534454
			},
			"e-124": {
				"id": "e-124",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-10",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-123"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "6127cdc6ce8538204e9ca32b|e65d81f4-dc7c-d65d-faff-843fe66dec97",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "6127cdc6ce8538204e9ca32b|e65d81f4-dc7c-d65d-faff-843fe66dec97",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1629998534454
			},
			"e-127": {
				"id": "e-127",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_CLICK",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-26",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-128"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "611d5acd7fdfc912d94e1941|ffa78ed2-01ec-5971-3916-410fb20005af",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611d5acd7fdfc912d94e1941|ffa78ed2-01ec-5971-3916-410fb20005af",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1598461266830
			},
			"e-129": {
				"id": "e-129",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OVER",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-27",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-130"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "7df4694b-7136-e6d8-fd86-19d525ef6058",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "7df4694b-7136-e6d8-fd86-19d525ef6058",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1630012153257
			},
			"e-130": {
				"id": "e-130",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_OUT",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-28",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-129"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"id": "7df4694b-7136-e6d8-fd86-19d525ef6058",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "7df4694b-7136-e6d8-fd86-19d525ef6058",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1630012153263
			},
			"e-131": {
				"id": "e-131",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "MOUSE_CLICK",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-26",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-132"
					}
				},
				"mediaQueries": ["main", "medium", "small", "tiny"],
				"target": {
					"appliesTo": "ELEMENT",
					"styleBlockIds": [],
					"id": "611d5acd7fdfc912d94e1941|5ef182db-a1ac-12c8-3238-ceb8163996fe"
				},
				"targets": [],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": null,
					"scrollOffsetUnit": null,
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1630014229444
			},
			"e-133": {
				"id": "e-133",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-134"
					},
					"instant": false
				},
				"mediaQueries": ["main"],
				"target": {
					"appliesTo": "ELEMENT",
					"styleBlockIds": [],
					"id": "611d5acd7fdfc912d94e1941|e644d21d-b19c-34cd-dc94-4d1367563abb"
				},
				"targets": [],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 0,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1630017944826
			},
			"e-135": {
				"id": "e-135",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-136"
					},
					"instant": false
				},
				"mediaQueries": ["main"],
				"target": {
					"appliesTo": "ELEMENT",
					"styleBlockIds": [],
					"id": "611d5acd7fdfc912d94e1941|ce505746-0e7b-574a-aa46-4963b52de41e"
				},
				"targets": [],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 250,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1630017965738
			},
			"e-137": {
				"id": "e-137",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-138"
					},
					"instant": false
				},
				"mediaQueries": ["main"],
				"target": {
					"appliesTo": "ELEMENT",
					"styleBlockIds": [],
					"id": "611d5acd7fdfc912d94e1941|ce505746-0e7b-574a-aa46-4963b52de422"
				},
				"targets": [],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 400,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1630018018308
			},
			"e-139": {
				"id": "e-139",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-140"
					},
					"instant": false
				},
				"mediaQueries": ["main"],
				"target": {
					"appliesTo": "ELEMENT",
					"styleBlockIds": [],
					"id": "611d5acd7fdfc912d94e1941|ce505746-0e7b-574a-aa46-4963b52de42d"
				},
				"targets": [],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 600,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1630018045127
			},
			"e-141": {
				"id": "e-141",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-142"
					},
					"instant": false
				},
				"mediaQueries": ["main"],
				"target": {
					"appliesTo": "ELEMENT",
					"styleBlockIds": [],
					"id": "611d5acd7fdfc912d94e1941|ce505746-0e7b-574a-aa46-4963b52de438"
				},
				"targets": [],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 750,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1630018066085
			},
			"e-143": {
				"id": "e-143",
				"name": "",
				"animationType": "preset",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "SLIDE_EFFECT",
					"config": {
						"actionListId": "slideInBottom",
						"autoStopEventId": "e-144"
					},
					"instant": false
				},
				"mediaQueries": ["main"],
				"target": {
					"appliesTo": "ELEMENT",
					"styleBlockIds": [],
					"id": "611d5acd7fdfc912d94e1941|a1f4d286-07e8-43d7-a176-9d4662c44b3e"
				},
				"targets": [],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 10,
					"scrollOffsetUnit": "%",
					"delay": 250,
					"direction": "BOTTOM",
					"effectIn": true
				},
				"createdOn": 1630018097377
			}
		},
		"actionLists": {
			"a": {
				"id": "a",
				"title": "Alert Exit",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-n-4",
						"actionTypeId": "STYLE_SIZE",
						"config": {
							"delay": 0,
							"easing": "inSine",
							"duration": 300,
							"target": {
								"useEventTarget": "PARENT",
								"selector": ".alert-header-section-wrapper",
								"selectorGuids": ["5956390e-0506-aa66-52af-42f4176d01cc"]
							},
							"heightValue": 0,
							"widthUnit": "PX",
							"heightUnit": "px",
							"locked": false
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1610572743886
			},
			"a-7": {
				"id": "a-7",
				"title": "dropdown open",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-7-n",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {},
							"value": 0,
							"unit": ""
						}
					}, {
						"id": "a-7-n-7",
						"actionTypeId": "TRANSFORM_ROTATE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-icon",
								"selectorGuids": ["bc36c71d-2d68-c73b-d98e-ee79544a4ac8"]
							},
							"zValue": 0,
							"xUnit": "DEG",
							"yUnit": "DEG",
							"zUnit": "deg"
						}
					}, {
						"id": "a-7-n-3",
						"actionTypeId": "STYLE_SIZE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {},
							"heightValue": 0,
							"widthUnit": "PX",
							"heightUnit": "px",
							"locked": false
						}
					}]
				}, {
					"actionItems": [{
						"id": "a-7-n-2",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {},
							"value": 1,
							"unit": ""
						}
					}, {
						"id": "a-7-n-8",
						"actionTypeId": "TRANSFORM_ROTATE",
						"config": {
							"delay": 0,
							"easing": "outSine",
							"duration": 400,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-icon",
								"selectorGuids": ["bc36c71d-2d68-c73b-d98e-ee79544a4ac8"]
							},
							"zValue": -180,
							"xUnit": "DEG",
							"yUnit": "DEG",
							"zUnit": "deg"
						}
					}, {
						"id": "a-7-n-4",
						"actionTypeId": "STYLE_SIZE",
						"config": {
							"delay": 0,
							"easing": "outSine",
							"duration": 300,
							"target": {},
							"widthUnit": "PX",
							"heightUnit": "AUTO",
							"locked": false
						}
					}]
				}],
				"useFirstGroupAsInitialState": true,
				"createdOn": 1625757674684
			},
			"a-8": {
				"id": "a-8",
				"title": "dropdown close",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-8-n-3",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {},
							"value": 0,
							"unit": ""
						}
					}, {
						"id": "a-8-n-5",
						"actionTypeId": "TRANSFORM_ROTATE",
						"config": {
							"delay": 0,
							"easing": "outSine",
							"duration": 300,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-icon",
								"selectorGuids": ["bc36c71d-2d68-c73b-d98e-ee79544a4ac8"]
							},
							"xValue": 0,
							"zValue": 0,
							"xUnit": "deg",
							"yUnit": "DEG",
							"zUnit": "deg"
						}
					}, {
						"id": "a-8-n-4",
						"actionTypeId": "STYLE_SIZE",
						"config": {
							"delay": 0,
							"easing": "outSine",
							"duration": 300,
							"target": {},
							"heightValue": 0,
							"widthUnit": "PX",
							"heightUnit": "px",
							"locked": false
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1625757674684
			},
			"a-11": {
				"id": "a-11",
				"title": "nav scroll up",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-11-n",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".alert-header-section-wrapper",
								"selectorGuids": ["5956390e-0506-aa66-52af-42f4176d01cc"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-11-n-6",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".brand",
								"selectorGuids": ["c06eb59e-1e26-efdd-b2c4-b877f1a31742"]
							},
							"xValue": 1,
							"yValue": 1,
							"locked": true
						}
					}, {
						"id": "a-11-n-2",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".search-section",
								"selectorGuids": ["efaf649f-935d-630b-7b4d-2f905ce467b0"]
							},
							"xValue": 1,
							"yValue": 1,
							"locked": true
						}
					}, {
						"id": "a-11-n-4",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".brand-image",
								"selectorGuids": ["754d1a5a-4e3a-2309-9261-0f261e8b2196"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-11-n-5",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"useEventTarget": "SIBLINGS",
								"selector": ".navbar",
								"selectorGuids": ["1b671a6e-5e8c-48dc-3d90-d00489cd2943"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1599769199640
			},
			"a-10": {
				"id": "a-10",
				"title": "nav scroll down",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-10-n-4",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".alert-header-section-wrapper",
								"selectorGuids": ["5956390e-0506-aa66-52af-42f4176d01cc"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-10-n-5",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".search-section",
								"selectorGuids": ["efaf649f-935d-630b-7b4d-2f905ce467b0"]
							},
							"xValue": 0.9,
							"yValue": 0.9,
							"locked": true
						}
					}, {
						"id": "a-10-n-6",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".brand",
								"selectorGuids": ["c06eb59e-1e26-efdd-b2c4-b877f1a31742"]
							},
							"xValue": 0.85,
							"yValue": 0.85,
							"locked": true
						}
					}, {
						"id": "a-10-n-8",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"useEventTarget": "SIBLINGS",
								"selector": ".navbar",
								"selectorGuids": ["1b671a6e-5e8c-48dc-3d90-d00489cd2943"]
							},
							"yValue": -30,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-10-n-9",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".brand",
								"selectorGuids": ["c06eb59e-1e26-efdd-b2c4-b877f1a31742"]
							},
							"yValue": 3,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1599769199640
			},
			"a-6": {
				"id": "a-6",
				"title": "search clicked",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-6-n",
						"actionTypeId": "GENERAL_DISPLAY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 0,
							"target": {
								"selector": ".search-bar",
								"selectorGuids": ["ef330c2f-b023-ba99-a9b2-bbe472e666f2"]
							},
							"value": "none"
						}
					}]
				}, {
					"actionItems": [{
						"id": "a-6-n-2",
						"actionTypeId": "GENERAL_DISPLAY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 0,
							"target": {
								"selector": ".search-bar",
								"selectorGuids": ["ef330c2f-b023-ba99-a9b2-bbe472e666f2"]
							},
							"value": "block"
						}
					}]
				}],
				"useFirstGroupAsInitialState": true,
				"createdOn": 1625755144906
			},
			"a-14": {
				"id": "a-14",
				"title": "search exit",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-14-n",
						"actionTypeId": "GENERAL_DISPLAY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 0,
							"target": {
								"selector": ".search-bar",
								"selectorGuids": ["ef330c2f-b023-ba99-a9b2-bbe472e666f2"]
							},
							"value": "none"
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1625755144906
			},
			"a-16": {
				"id": "a-16",
				"title": "Alert CB Hover",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-16-n",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "ease",
							"duration": 600,
							"target": {
								"useEventTarget": true,
								"id": "611d5acd7fdfc912d94e1941|4b0dedaf-d1af-1cad-45f7-9ede2d0ea7db"
							},
							"yValue": -8,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1626960959414
			},
			"a-17": {
				"id": "a-17",
				"title": "Alert CB Hover Out",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-17-n",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "easeOut",
							"duration": 500,
							"target": {
								"useEventTarget": true,
								"id": "611d5acd7fdfc912d94e1941|4b0dedaf-d1af-1cad-45f7-9ede2d0ea7db"
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1626960991943
			},
			"a-18": {
				"id": "a-18",
				"title": "Scroll Into - Slider",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-18-n",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".text-block-3",
								"selectorGuids": ["3bf4a1ef-4d9b-1906-c4a3-30f382c406a6"]
							},
							"value": 0,
							"unit": ""
						}
					}, {
						"id": "a-18-n-15",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".text-block-3",
								"selectorGuids": ["3bf4a1ef-4d9b-1906-c4a3-30f382c406a6"]
							},
							"yValue": 48,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-18-n-6",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".heading-3",
								"selectorGuids": ["0adcddc5-39a3-2182-12dd-a7d1e421c2b7"]
							},
							"yValue": 48,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-18-n-8",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".heading-3",
								"selectorGuids": ["0adcddc5-39a3-2182-12dd-a7d1e421c2b7"]
							},
							"value": 0,
							"unit": ""
						}
					}]
				}, {
					"actionItems": [{
						"id": "a-18-n-9",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 200,
							"easing": "outQuint",
							"duration": 2000,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".text-block-3",
								"selectorGuids": ["3bf4a1ef-4d9b-1906-c4a3-30f382c406a6"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-18-n-12",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 200,
							"easing": "outQuint",
							"duration": 2500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".heading-3",
								"selectorGuids": ["0adcddc5-39a3-2182-12dd-a7d1e421c2b7"]
							},
							"value": 1,
							"unit": ""
						}
					}, {
						"id": "a-18-n-13",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 200,
							"easing": "outQuint",
							"duration": 2500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".text-block-3",
								"selectorGuids": ["3bf4a1ef-4d9b-1906-c4a3-30f382c406a6"]
							},
							"value": 1,
							"unit": ""
						}
					}, {
						"id": "a-18-n-14",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 300,
							"easing": "outQuint",
							"duration": 2000,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".heading-3",
								"selectorGuids": ["0adcddc5-39a3-2182-12dd-a7d1e421c2b7"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}]
				}],
				"useFirstGroupAsInitialState": true,
				"createdOn": 1626881401667
			},
			"a-21": {
				"id": "a-21",
				"title": "News CB Hover",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-21-n",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "ease",
							"duration": 600,
							"target": {
								"useEventTarget": true,
								"id": "611d5acd7fdfc912d94e1941|4b0dedaf-d1af-1cad-45f7-9ede2d0ea7db"
							},
							"yValue": -8,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1626960959414
			},
			"a-22": {
				"id": "a-22",
				"title": "News CB Hover Out",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-22-n",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "easeOut",
							"duration": 500,
							"target": {
								"useEventTarget": true,
								"id": "611d5acd7fdfc912d94e1941|4b0dedaf-d1af-1cad-45f7-9ede2d0ea7db"
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1626960991943
			},
			"a-23": {
				"id": "a-23",
				"title": "dropdown open 2",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-23-n",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-list",
								"selectorGuids": ["c06eb59e-1e26-efdd-b2c4-b877f1a31745"]
							},
							"value": 0,
							"unit": ""
						}
					}, {
						"id": "a-23-n-2",
						"actionTypeId": "TRANSFORM_ROTATE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-icon",
								"selectorGuids": ["bc36c71d-2d68-c73b-d98e-ee79544a4ac8"]
							},
							"zValue": 0,
							"xUnit": "DEG",
							"yUnit": "DEG",
							"zUnit": "deg"
						}
					}, {
						"id": "a-23-n-3",
						"actionTypeId": "STYLE_SIZE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-list",
								"selectorGuids": ["c06eb59e-1e26-efdd-b2c4-b877f1a31745"]
							},
							"heightValue": 0,
							"widthUnit": "PX",
							"heightUnit": "px",
							"locked": false
						}
					}]
				}, {
					"actionItems": [{
						"id": "a-23-n-4",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-list",
								"selectorGuids": ["c06eb59e-1e26-efdd-b2c4-b877f1a31745"]
							},
							"value": 1,
							"unit": ""
						}
					}, {
						"id": "a-23-n-5",
						"actionTypeId": "TRANSFORM_ROTATE",
						"config": {
							"delay": 0,
							"easing": "outSine",
							"duration": 400,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-icon",
								"selectorGuids": ["bc36c71d-2d68-c73b-d98e-ee79544a4ac8"]
							},
							"zValue": -180,
							"xUnit": "DEG",
							"yUnit": "DEG",
							"zUnit": "deg"
						}
					}, {
						"id": "a-23-n-6",
						"actionTypeId": "STYLE_SIZE",
						"config": {
							"delay": 0,
							"easing": "outSine",
							"duration": 300,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-list",
								"selectorGuids": ["c06eb59e-1e26-efdd-b2c4-b877f1a31745"]
							},
							"widthUnit": "PX",
							"heightUnit": "AUTO",
							"locked": false
						}
					}]
				}],
				"useFirstGroupAsInitialState": true,
				"createdOn": 1625757674684
			},
			"a-24": {
				"id": "a-24",
				"title": "dropdown close 2",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-24-n",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 200,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-list",
								"selectorGuids": ["c06eb59e-1e26-efdd-b2c4-b877f1a31745"]
							},
							"value": 0,
							"unit": ""
						}
					}, {
						"id": "a-24-n-2",
						"actionTypeId": "TRANSFORM_ROTATE",
						"config": {
							"delay": 0,
							"easing": "outSine",
							"duration": 300,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-icon",
								"selectorGuids": ["bc36c71d-2d68-c73b-d98e-ee79544a4ac8"]
							},
							"xValue": 0,
							"zValue": 0,
							"xUnit": "deg",
							"yUnit": "DEG",
							"zUnit": "deg"
						}
					}, {
						"id": "a-24-n-3",
						"actionTypeId": "STYLE_SIZE",
						"config": {
							"delay": 0,
							"easing": "outSine",
							"duration": 300,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-list",
								"selectorGuids": ["c06eb59e-1e26-efdd-b2c4-b877f1a31745"]
							},
							"heightValue": 0,
							"widthUnit": "PX",
							"heightUnit": "px",
							"locked": false
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1625757674684
			},
			"a-26": {
				"id": "a-26",
				"title": "alert pop up exit",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-26-n",
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "PARENT",
								"selector": ".alert-pop-up-wrapper",
								"selectorGuids": ["f371d9d5-fac7-9089-73ef-f0061ae474c3"]
							},
							"value": 0,
							"unit": ""
						}
					}]
				}, {
					"actionItems": [{
						"id": "a-26-n-2",
						"actionTypeId": "GENERAL_DISPLAY",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 0,
							"target": {
								"useEventTarget": "PARENT",
								"selector": ".alert-pop-up-wrapper",
								"selectorGuids": ["f371d9d5-fac7-9089-73ef-f0061ae474c3"]
							},
							"value": "none"
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1598461286200
			},
			"a-27": {
				"id": "a-27",
				"title": "dropdown open translate",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-27-n-2",
						"actionTypeId": "TRANSFORM_ROTATE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 500,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-icon.translate",
								"selectorGuids": ["bc36c71d-2d68-c73b-d98e-ee79544a4ac8", "c6555a3b-eecd-2cc3-a014-a580b25a4d82"]
							},
							"zValue": 0,
							"xUnit": "DEG",
							"yUnit": "DEG",
							"zUnit": "deg"
						}
					}]
				}, {
					"actionItems": [{
						"id": "a-27-n-5",
						"actionTypeId": "TRANSFORM_ROTATE",
						"config": {
							"delay": 0,
							"easing": "outSine",
							"duration": 400,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-icon.translate",
								"selectorGuids": ["bc36c71d-2d68-c73b-d98e-ee79544a4ac8", "c6555a3b-eecd-2cc3-a014-a580b25a4d82"]
							},
							"zValue": -180,
							"xUnit": "DEG",
							"yUnit": "DEG",
							"zUnit": "deg"
						}
					}]
				}],
				"useFirstGroupAsInitialState": true,
				"createdOn": 1625757674684
			},
			"a-28": {
				"id": "a-28",
				"title": "dropdown close translate",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-28-n-2",
						"actionTypeId": "TRANSFORM_ROTATE",
						"config": {
							"delay": 0,
							"easing": "outSine",
							"duration": 300,
							"target": {
								"useEventTarget": "CHILDREN",
								"selector": ".dropdown-icon.translate",
								"selectorGuids": ["bc36c71d-2d68-c73b-d98e-ee79544a4ac8", "c6555a3b-eecd-2cc3-a014-a580b25a4d82"]
							},
							"xValue": 0,
							"zValue": 0,
							"xUnit": "deg",
							"yUnit": "DEG",
							"zUnit": "deg"
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1625757674684
			},
			"slideInBottom": {
				"id": "slideInBottom",
				"useFirstGroupAsInitialState": true,
				"actionItemGroups": [{
					"actionItems": [{
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"duration": 0,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"value": 0
						}
					}]
				}, {
					"actionItems": [{
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"duration": 0,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"xValue": 0,
							"yValue": 100,
							"xUnit": "PX",
							"yUnit": "PX",
							"zUnit": "PX"
						}
					}]
				}, {
					"actionItems": [{
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "outQuart",
							"duration": 1000,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"xValue": 0,
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "PX",
							"zUnit": "PX"
						}
					}, {
						"actionTypeId": "STYLE_OPACITY",
						"config": {
							"delay": 0,
							"easing": "outQuart",
							"duration": 1000,
							"target": {
								"id": "N/A",
								"appliesTo": "TRIGGER_ELEMENT",
								"useEventTarget": true
							},
							"value": 1
						}
					}]
				}]
			}
		},
		"site": {
			"mediaQueries": [{
				"key": "main",
				"min": 992,
				"max": 10000
			}, {
				"key": "medium",
				"min": 768,
				"max": 991
			}, {
				"key": "small",
				"min": 480,
				"max": 767
			}, {
				"key": "tiny",
				"min": 0,
				"max": 479
			}]
		}
	});
	/* eslint-enable */
});

function addStickyHeader() {
	if (jQuery(window).scrollTop() > 1) {
		jQuery('.navbar').addClass('sticky');
	}
	else {
		jQuery('.navbar').removeClass('sticky');
	}
}

function setCookie(cname, cvalue) {
	document.cookie = cname + "=" + cvalue + ";path=/";
}

// Calculate page padding
$(window).on('load resize', function() {
	calculateLeftColumnMargin();
});

function calculateLeftColumnMargin() {
	const navbarHeight = jQuery(".navbar").outerHeight()
	jQuery(".left-nav-column.with-margin").css("margin-top", navbarHeight + 10);
}


/******** Custom styles ********/
/******************************/
jQuery(document).ready(function($) {
	/* Alerts */
	$( ".alert-close" ).click(function() {
		$(this).parent().hide();
	});

	/* Acordion */
	$( ".accordion-title" ).click(function() {
		$(this).next('.accordion-content').toggle();
	});

	/* Expanding Card */
	$( ".expanding-more-info-label" ).click(function() {
		$(this).next('.expanding-more-info-content').toggle();
	});
});
